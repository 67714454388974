.offcanvas {
  justify-content: center;
  color: black;
  display: flex;
}

.scrollview {
  max-height: 100%;
  overflow-y: scroll;
  overflow-x: none;
  -ms-overflow-style: none;
  scrollbar-width: none;
  z-index: 1045;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scrollview::-webkit-scrollbar {
  display: none;
}

.offcanvas-header {
  justify-content: center;
  font-family: "SpartanMB-Regular";
  font-weight: bold;
}

.offcanvas-top {
  height: 100%;
  border-bottom: none;
}

.offcanvas-h2 {
  background-color: transparent;
  text-align: center;
  font-family: "SpartanMB-Regular";
}

.offcanvas-stream-buy,
.offcanvas-stream-buy:focus {
  background-color: transparent;
  border: solid 2px black;
  opacity: 0.8;
  transition: 0.2s;
  text-decoration: none;
  margin: 0 5px 5px 5px;
  color: black;
  width: 250px;
}

.offcanvas-stream-buy:hover {
  background-color: transparent;
  border: solid 2px #dc8840;
  opacity: 1;
  transition: 0.2s;
  color: #dc8840;
}

.offcanvas-background {
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  filter: blur(40px);
}
