.video-container {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
    justify-content: center;
    margin-bottom: 20px;
}
.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}