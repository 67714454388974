.card-body {
  text-align: center;
  font-style: italic;
  padding: 0px;
  margin-bottom: 20px;
}

.card-title {
  margin-bottom: 15px;
  text-align: center;
  margin-top: 15px;
  font-weight: bold;
}

.card-subtitle {
  margin-bottom: 5px;
  text-align: center;
}

.release-card {
  background-color: transparent;
  width: 18rem;
}

.release-card-image {
  transition: 0.4s;
  transform: scale(1);
}

.release-card-image:hover {
  transition: 0.4s;
  transform: scale(1.1);
  cursor: pointer;
}

.release-card-toogle {
  background-color: transparent;
  border-color: transparent;
}

.release-card-toogle:hover {
  background-color: transparent;
  border-color: transparent;
}
