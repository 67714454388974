.navbar {
    background-image: url('../images/back.png');
}

.navbar-collapse {
    flex-grow: unset;
}

.navbar > .container, 
.navbar > .container-fluid, 
.navbar > .container-sm, 
.navbar > .container-md, 
.navbar > .container-lg, 
.navbar > .container-xl, 
.navbar > .container-xxl {
    align-items: flex-end;
}

.logo {
    transition: 0.2s;
}

.logo:hover {
    filter: invert(25%) sepia(7%) saturate(5831%) hue-rotate(328deg) brightness(89%) contrast(93%);
    transition: 0.2s;
}

.social-media {
    width: 25px;
    height: 25px;
    transition: 0.2s;
}

.social-media:hover {
    filter: brightness(1.45); 
    transition: 0.2s;
}

.navbar-entry {
    opacity: 1;
    transition: 0.4s;
}

.navbar-entry:focus {
    color: #37921d;
}

.navbar-entry:hover {
    color: #dc8840;
    transition: 0.5s;
}
