.home_release_image {
    width: 50%;
    height: 50%;
    transition: 1s;
}

.home_release_image:hover {
  transition: 1s;
  transform: scale(1.1);
  cursor: pointer;
}