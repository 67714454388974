.date-container {
    text-align: center;
}

.date-div {
    margin-bottom: 5px;
}

.date-h2 {
    margin-bottom: 1rem;
}

.no-upcoming-p {
    margin-bottom: 0;
}